import React from "react";
import {DateTime} from "luxon";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Icons
//---------------------------------------------------------------------------
import Assessment from "@mui/icons-material/Assessment";
import Assignment from "@mui/icons-material/Assignment";
import AssignmentTurnedIn from "@mui/icons-material/AssignmentTurnedIn";
import Bookmark from "@mui/icons-material/Bookmark";
import Business from "@mui/icons-material/Business";
import Check from "@mui/icons-material/Check";
import Comment from "@mui/icons-material/Comment";
import EditCalendar from "@mui/icons-material/EditCalendar";
import Email from "@mui/icons-material/Email";
import FindInPage from "@mui/icons-material/FindInPage";
import FolderShared from "@mui/icons-material/FolderShared";
import GppBad from "@mui/icons-material/GppBad";
import GppGood from "@mui/icons-material/GppGood";
import History from "@mui/icons-material/History";
import Inbox from "@mui/icons-material/Inbox";
import Language from "@mui/icons-material/Language";
import LibraryBooks from "@mui/icons-material/LibraryBooks";
import PermContactCalendar from "@mui/icons-material/PermContactCalendar";
import Person from "@mui/icons-material/Person";
import Phone from "@mui/icons-material/Phone";
import Place from "@mui/icons-material/Place";
import PlayCircle from "@mui/icons-material/PlayCircle";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import Scale from "@mui/icons-material/Scale";
import SecurityUpdateWarning from "@mui/icons-material/SecurityUpdateWarning";
import Shield from "@mui/icons-material/Shield";
import Smartphone from "@mui/icons-material/Smartphone";
import StopCircle from "@mui/icons-material/StopCircle";
import Straighten from "@mui/icons-material/Straighten";
import SupervisorAccount from "@mui/icons-material/SupervisorAccount";
import Update from "@mui/icons-material/Update";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";
import List from "@mui/material/List";

//---------------------------------------------------------------------------
// TZ Components
//---------------------------------------------------------------------------
import {useInterval} from "@tzmedical/react-hooks";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import axios from "../../../axiosClient.js";
import StudyDetailsDialog from "../../../dialogs/StudyDialogs/StudyDetails/StudyDetailsDialog.jsx";
import Alert from "../../../shared/react/Alert.jsx";
import LinkifyText from "../../../shared/react/LinkifyText.jsx";
import ShowMoreText from "../../../shared/react/ShowMoreText.jsx";
import DateAndTime from "../../DateAndTime/DateAndTime.jsx";
import useEnvironmentVariables from "../../hooks/useEnvironmentVariables.jsx";
import useJwt from "../../hooks/useJwt.jsx";
import useStudyTypeNames from "../../hooks/useStudyTypeNames.jsx";
import StudyDetailsItem from "../StudyDetailsItem.jsx";
import StudyNotes from "../StudyNotes.jsx";

// Unless we can get socket.io or long polling working, fetching the data
// every 3 minutes should keep things from getting too stale
const DATA_REFRESH_INTERVAL_MS = 1000 * 60 * 3;

function StudyDetails({
  // Props
  study,
  isTriageItem = false,
  lastModifiedByUser,
}) {
  //---------------------------------------------------------------------------
  // Global variables
  //---------------------------------------------------------------------------
  const {features} = useEnvironmentVariables();
  const displayableStudyTypes = useStudyTypeNames();

  //---------------------------------------------------------------------------
  // Error alerting state management
  //---------------------------------------------------------------------------
  const [error, setError] = React.useState(null);

  const {isInAnyRole} = useJwt();

  //---------------------------------------------------------------------------
  // Load data from the API
  //---------------------------------------------------------------------------
  const [inboxCount, setInboxCount] = React.useState(null);
  const [autoClassifiedCount, setAutoClassifiedCount] = React.useState(null);
  const [reportCount, setReportCount] = React.useState(null);
  const [latestStudyHours, setLatestStudyHours] = React.useState(null);

  const getStudyDetailsData = React.useCallback(async () => {
    try {
      // Counts are not displayed on failed studies
      if (study.studyState !== "failed") {
        const {
          headers: {count: inboxResponseCount},
        } = await axios({
          method: "get",
          url: "/inboxItems",
          headers: {"tzmedical-br-origin": "study-details-inbox-count"},
          params: {
            "$enrollment.study.id$": study.id,
            completed: false,
            limit: 0,
          },
        });
        setInboxCount(inboxResponseCount);

        if (features.algorithmClassification) {
          const {
            headers: {count: autoResponseCount},
          } = await axios({
            method: "get",
            url: "/inboxItems",
            headers: {"tzmedical-br-origin": "study-details-auto-classified-count"},
            params: {
              "$enrollment.study.id$": study.id,
              autoClassified: true,
              completed: true,
              limit: 0,
            },
          });
          setAutoClassifiedCount(autoResponseCount);
        }

        // Triage Techs do not have access to the reports page
        if (!isInAnyRole(["triageTech"])) {
          const {
            headers: {count: reportResponseCount},
          } = await axios({
            method: "get",
            url: "/generatedReports",
            headers: {"tzmedical-br-origin": "study-details-report-count"},
            params: {
              studyId: study.id,
              status: ["submitted", "published", "signed", "printed", "rejectedByPhysician"],
              limit: 0,
            },
          });
          setReportCount(reportResponseCount);
        }
      }
      setError(null);
    } catch (err) {
      if (err.response?.status !== 500) {
        setError(err?.message);
      } else {
        setError(null);
      }
    }

    if (study.configuredDuration) {
      setLatestStudyHours(study.configuredDuration);
    } else {
      // If somehow the configured duration is null, use the latest update settings action
      try {
        if (study.currentEnrollment?.id) {
          const {data: latestSettings} = await axios({
            method: "get",
            url: `/actions/latestSettings/${study.currentEnrollment?.id}`,
            params: {...(study.studyEndDate && {upperBoundTimestamp: study.studyEndDate})},
          });

          if (latestSettings?.studyHours) {
            setLatestStudyHours(latestSettings.studyHours);
          }
        }
      } catch (err) {
        if (err.response?.status !== 404) {
          setError(err.message);
        }
      }
    }
  }, [
    study.configuredDuration,
    study.id,
    study.currentEnrollment?.id,
    study.studyEndDate,
    study.studyState,
    features.algorithmClassification,
    isInAnyRole,
  ]);

  useInterval(getStudyDetailsData, DATA_REFRESH_INTERVAL_MS, true);

  //---------------------------------------------------------------------------
  // Handle clicks to open pages with pre-filled searches
  //
  // E.g. clicking on the report count opens the report page with study ID
  // filled in the search bar
  //---------------------------------------------------------------------------
  const handleOpenInNewTab = React.useCallback((searchText) => {
    window.localStorage.setItem(
      "search",
      JSON.stringify({
        text: searchText,
        timeExpired: DateTime.now().plus({seconds: 30}).toMillis(),
      })
    );
  }, []);
  const handleOpenInNewTabAutoClassified = React.useCallback(
    () => handleOpenInNewTab(`study:${study.id} is:auto-classified`),
    [handleOpenInNewTab, study.id]
  );
  const handleOpenInNewTabStudy = React.useCallback(
    () => handleOpenInNewTab(`study:${study.id}`),
    [handleOpenInNewTab, study.id]
  );

  //---------------------------------------------------------------------------
  // Calculate and memoize displayed values
  //---------------------------------------------------------------------------
  const invalidStartDate = React.useMemo(() => {
    return DateTime.fromISO(study.studyStartDate).year < 2010;
  }, [study.studyStartDate]);

  const invalidEndDate = React.useMemo(() => {
    return DateTime.fromISO(study.studyEndDate).year < 2010;
  }, [study.studyEndDate]);

  const displayedSerial = React.useMemo(() => {
    const enrollments = study.allEnrollments;
    const arrowCharacter = String.fromCharCode(8594);
    const oldDeviceSerial = study.currentEnrollment?.tzSerial || "Unknown";

    // If there's an incomplete replace device action, we want to display the old and new device serials
    if (Array.isArray(enrollments) && enrollments.length >= 2 && !enrollments[0].scpFileReceived) {
      return `${oldDeviceSerial} ${arrowCharacter} ${enrollments[0].tzSerial}`;
    }
    return oldDeviceSerial;
  }, [study]);

  const displayedStudyStartDate = React.useMemo(() => {
    // If we've received data, use that timestamp
    // For a Holter study displays the default start date or the updated one if it has been changed
    if (study.dataReceived) {
      if (invalidStartDate) {
        return "Unknown";
      }
      return <DateAndTime datetime={study.studyStartDate} zone={study.timeZone} />;
    }

    // Otherwise, we have no way of knowing when the recording started on the device
    // @TODO use the user reported start time from the Trident Patient app if it exists
    return "Unknown";
  }, [study.dataReceived, study.studyStartDate, study.timeZone, invalidStartDate]);

  const displayedStudyEndDate = React.useMemo(() => {
    // If non-Holter study has ended, display the end date
    if (!["holter", "extendedHolter"].includes(study.studyType) && study.studyEndDate) {
      if (invalidEndDate) {
        return "Unknown";
      }
      return <DateAndTime datetime={study.studyEndDate} zone={study.timeZone} />;
    }
    // Otherwise if start + duration > now, the recording is still in progress
    if (
      !["holter", "extendedHolter"].includes(study.studyType) &&
      study.dataReceived &&
      DateTime.fromISO(study.studyStartDate).plus({hours: latestStudyHours}) > DateTime.now()
    ) {
      return "In Progress";
    }

    // Otherwise, we cannot predict when the study will end
    return "Unknown";
  }, [
    study.studyType,
    study.studyEndDate,
    study.dataReceived,
    study.studyStartDate,
    study.timeZone,
    latestStudyHours,
    invalidEndDate,
  ]);

  const prescribedDuration = React.useMemo(() => {
    if (study.configuredDuration) {
      const days = Math.ceil(study.configuredDuration / 24);
      return `${days} Day${days === 1 ? "" : "s"}`;
    }

    if (latestStudyHours) {
      const days = Math.ceil(latestStudyHours / 24);
      return `${days} Day${days === 1 ? "" : "s"}`;
    }

    return "Unknown";
  }, [latestStudyHours, study.configuredDuration]);

  const recordedDuration = React.useMemo(() => {
    if (study.recordedDuration) {
      const days = Math.ceil(study.recordedDuration / 24);
      return `${days} Day${days === 1 ? "" : "s"}`;
    }

    return null;
  }, [study.recordedDuration]);

  const displayedFinalizedDate = React.useMemo(
    () => <DateAndTime datetime={study.finalizedAt} zone={study.timeZone} />,
    [study.finalizedAt, study.timeZone]
  );

  const displayedStudyType = React.useMemo(() => {
    const studyType = study.inboxContract === "evaluation" ? "Evaluation study" : "Study";
    return `${studyType} created: `;
  }, [study.inboxContract]);

  const displayedStudyCreationDate = React.useMemo(() => {
    return <DateAndTime datetime={study.createdAt} zone={study.timeZone} />;
  }, [study.createdAt, study.timeZone]);

  const displayedStudyCreationUser = React.useMemo(() => {
    const user = study.createdByUser?.fullName;

    return user ? ` by ${user}` : null;
  }, [study.createdByUser?.fullName]);

  const displayedPatientDob = React.useMemo(() => {
    if (study.studyDetails?.patientAge) {
      // @TODO BN-3380: remove replaceAll when date is stored in ISO
      return `${study.studyDetails?.patientDob?.replaceAll("/", "-")} (${study.studyDetails?.patientAge})`;
    }
    // @TODO BN-3380: remove replaceAll when date is stored in ISO
    return study.studyDetails?.patientDob?.replaceAll("/", "-") || "";
  }, [study.studyDetails?.patientAge, study.studyDetails?.patientDob]);

  const displayDowngradeAuthorizationMessage = React.useMemo(() => {
    return (
      features.downgradeAuthorized &&
      (study.studyType.startsWith("mct") || study.studyType.toLowerCase().includes("extendedholter"))
    );
  }, [features.downgradeAuthorized, study.studyType]);

  const downgradeAuthorizedIcon = React.useMemo(() => {
    if (study.downgradeAuthorized === null) {
      return Shield;
    }
    return study.downgradeAuthorized ? GppGood : GppBad;
  }, [study.downgradeAuthorized]);

  const downgradeAuthorizationMessage = React.useMemo(() => {
    let downgradedStudyType = "CEM";
    if (study.studyType === "extendedHolter") {
      downgradedStudyType = "Holter";
    } else if (study.studyType === "wirelessExtendedHolter") {
      downgradedStudyType = "Wireless Holter";
    }

    let detail = `Authorization is not set for downgrading to ${downgradedStudyType} study`;
    if (study.downgradeAuthorized === true) {
      detail = `Authorized to downgrade to ${downgradedStudyType} study`;
    } else if (study.downgradeAuthorized === false) {
      detail = `NOT authorized to downgrade to ${downgradedStudyType} study`;
    }
    return detail;
  }, [study.studyType, study.downgradeAuthorized]);

  //---------------------------------------------------------------------------
  // Handle clicks to copy value to clipboard
  //---------------------------------------------------------------------------
  const [message, setMessage] = React.useState(null);
  const handleCopyToClipboard = React.useCallback((clipboardValue) => {
    try {
      navigator.clipboard.writeText(clipboardValue);
      setMessage("Successfully copied to clipboard");
    } catch (err) {
      setError(`Unable to copy contents: ${err.message}`);
    }
  }, []);
  const handleCopyDeviceSerial = React.useCallback(
    () => handleCopyToClipboard(study.currentEnrollment?.tzSerial || ""),
    [handleCopyToClipboard, study.currentEnrollment?.tzSerial]
  );
  const handleCopyStudyId = React.useCallback(
    () => handleCopyToClipboard(`${study.id}`),
    [handleCopyToClipboard, study.id]
  );
  const handleCopyPatientId = React.useCallback(
    () => handleCopyToClipboard(study.currentEnrollment?.deviceEnrollmentId || ""),
    [handleCopyToClipboard, study.currentEnrollment?.deviceEnrollmentId]
  );
  const handleCopyOrderNumber = React.useCallback(
    () => handleCopyToClipboard(study.orderNumber || ""),
    [handleCopyToClipboard, study.orderNumber]
  );

  //---------------------------------------------------------------------------
  // Rendering
  //---------------------------------------------------------------------------
  return (
    <Box sx={{width: "100%"}}>
      <Alert message={error} setMessage={setError} level="error" variant="snackbar" />
      <Alert message={message} setMessage={setMessage} level="info" variant="snackbar" />

      <Grid container spacing={0} columns={10} data-cy="study-details" id="study-details">
        {/* -------------- STUDY INFO -------------- */}
        <Grid
          size={{xs: 10, md: 2}}
          sx={{
            borderBottom: {xs: "1px solid", md: "none"},
            borderRight: {xs: "none", md: "1px solid"},
            borderBottomColor: {xs: "divider"},
            borderRightColor: {md: "divider"},
          }}
        >
          <List dense disablePadding sx={{p: 1}}>
            {/* DEVICE SERIAL */}
            <StudyDetailsItem
              icon={Smartphone}
              value={displayedSerial}
              handleCopyToClipboard={handleCopyDeviceSerial}
              data-cy="device-serial"
            />

            {/* STUDY ID */}
            <StudyDetailsItem
              icon={FindInPage}
              value={`Study ID: ${study.id}`}
              handleCopyToClipboard={handleCopyStudyId}
              handleOpenInNewTab={handleOpenInNewTabStudy}
              pageToOpen="studies"
              data-cy="study-id"
            />

            {/* ORDER NUMBER */}
            <StudyDetailsItem
              prefix="Order Number: "
              icon={ReceiptLongIcon}
              value={study.orderNumber || ""}
              handleCopyToClipboard={handleCopyOrderNumber}
              data-cy="order-number"
            />

            {/* PATIENT ID */}
            <StudyDetailsItem
              icon={SecurityUpdateWarning}
              value={study.currentEnrollment?.deviceEnrollmentId}
              handleCopyToClipboard={handleCopyPatientId}
              data-cy="device-enrollment-id"
            />

            {/* STUDY TYPE */}
            <StudyDetailsItem
              icon={Assignment}
              value={displayableStudyTypes[study.studyType]}
              data-cy="study-type"
            />

            {/* FACILITY */}
            <StudyDetailsItem icon={Business} value={study.facility.name} data-cy="facility" />

            {/* STUDY START DATE */}
            <StudyDetailsItem
              prefix="Start: "
              icon={PlayCircle}
              value={displayedStudyStartDate}
              data-cy="study-start-date"
              warning={invalidStartDate ? "The device reported an invalid timestamp" : ""}
            />

            {/* STUDY END DATE */}
            <StudyDetailsItem
              prefix="End: "
              icon={StopCircle}
              value={displayedStudyEndDate}
              data-cy="study-end-date"
              warning={invalidEndDate ? "The device reported an invalid timestamp" : ""}
            />

            {/* DURATION */}
            <StudyDetailsItem
              prefix="Prescribed Duration: "
              icon={Update}
              value={prescribedDuration}
              data-cy="prescribed-duration"
            />

            {recordedDuration && (
              <StudyDetailsItem
                prefix="Recorded Duration: "
                icon={Update}
                value={recordedDuration}
                data-cy="recorded-duration"
              />
            )}

            {/* FINALIZED AT DATE */}
            {!!study.finalizedAt && (
              <StudyDetailsItem
                prefix="Finalized: "
                icon={AssignmentTurnedIn}
                value={displayedFinalizedDate}
                data-cy="finalized-at-date"
              />
            )}

            {study.studyState !== "failed" && (
              <>
                {/* REPORT COUNT */}
                {!isInAnyRole(["triageTech"]) && (
                  <StudyDetailsItem
                    icon={LibraryBooks}
                    value={`${reportCount || ""} Available Report${reportCount !== "1" ? "s" : ""}`}
                    handleOpenInNewTab={handleOpenInNewTabStudy}
                    pageToOpen="reports"
                    data-cy="report-count"
                  />
                )}

                {/* UNANALYZED ITEM COUNT */}
                {!isInAnyRole(["clinicalStaff", "physician"]) && (
                  <StudyDetailsItem
                    icon={Inbox}
                    value={`${inboxCount || ""} Unanalyzed Item${inboxCount !== "1" ? "s" : ""}`}
                    handleOpenInNewTab={handleOpenInNewTabStudy}
                    pageToOpen="analyze"
                    data-cy="inbox-item-count"
                  />
                )}

                {/* AUTO-CLASSIFIED ARTIFACT COUNT */}
                {features.algorithmClassification && (
                  <StudyDetailsItem
                    icon={Check}
                    value={`${autoClassifiedCount || ""} Auto-classified Artifact Item${autoClassifiedCount !== "1" ? "s" : ""}`}
                    handleOpenInNewTab={handleOpenInNewTabAutoClassified}
                    pageToOpen="completed"
                    data-cy="auto-classified-count"
                  />
                )}
              </>
            )}
          </List>
        </Grid>

        {/* -------------- STUDY INDICATION & STUDY NOTES -------------- */}
        <Grid
          size={{xs: 10, md: 4}}
          sx={{
            borderBottom: {xs: "1px solid", md: "none"},
            borderRight: {xs: "none", md: "1px solid"},
            borderBottomColor: {xs: "divider"},
            borderRightColor: {md: "divider"},
          }}
          data-cy={`${study.id}-study-notes-table`}
        >
          <List dense disablePadding sx={{p: 1}}>
            {lastModifiedByUser && (
              <StudyDetailsItem
                icon={History}
                value={`Last modified by ${lastModifiedByUser}`}
                data-cy="last-modified-by"
              />
            )}

            <StudyDetailsItem
              icon={EditCalendar}
              prefix={displayedStudyType}
              value={displayedStudyCreationDate}
              suffix={displayedStudyCreationUser}
              data-cy="study-creation-info"
            />

            {displayDowngradeAuthorizationMessage && (
              <StudyDetailsItem
                icon={downgradeAuthorizedIcon}
                value={downgradeAuthorizationMessage}
                data-cy="downgrade-authorization"
              />
            )}

            <StudyDetailsItem
              icon={Assessment}
              prefix="Indication: "
              value={study.studyIndication}
              data-cy="study-indication"
            />

            <StudyNotes study={study} setError={setError} />
          </List>
        </Grid>

        {/* -------------- PATIENT & ORDERING PHYSICIAN INFO -------------- */}
        <Grid size={{xs: 10, sm: 5, md: 2}}>
          <List dense disablePadding sx={{p: 1}}>
            {/* PATIENT NAME */}
            {!isTriageItem && (
              <StudyDetailsItem
                icon={Person}
                value={
                  study.studyDetails?.patientName !== study.currentEnrollment?.tzSerial
                    ? study.studyDetails?.patientName
                    : ""
                }
                data-cy="patient-name"
              />
            )}

            {/* PATIENT ID */}
            {!isTriageItem && (
              <StudyDetailsItem icon={Bookmark} value={study.studyDetails?.patientId} data-cy="patient-id" />
            )}

            {/* PATIENT LANGUAGE */}
            {!isTriageItem && (
              <StudyDetailsItem
                icon={Language}
                value={study.studyDetails?.patientLanguage}
                data-cy="patient-language"
              />
            )}

            {/* PATIENT DOB */}
            {!isTriageItem && (
              <StudyDetailsItem
                icon={PermContactCalendar}
                value={displayedPatientDob}
                data-cy="patient-dob"
              />
            )}
            {isTriageItem && (
              <StudyDetailsItem
                icon={PermContactCalendar}
                value={study.studyDetails?.patientAge || "Age unknown"}
                data-cy="patient-age"
              />
            )}

            {/* PATIENT SEX */}
            <StudyDetailsItem
              icon={FolderShared}
              value={study.studyDetails?.patientGender}
              data-cy="patient-gender"
            />

            {/* PATIENT HEIGHT */}
            <StudyDetailsItem
              icon={Straighten}
              value={study.studyDetails?.patientHeight}
              data-cy="patient-height"
            />

            {/* PATIENT WEIGHT */}
            <StudyDetailsItem
              icon={Scale}
              value={study.studyDetails?.patientWeight}
              data-cy="patient-weight"
            />

            {/* PATIENT PHONE NUMBER */}
            {!isTriageItem && (
              <StudyDetailsItem
                icon={Phone}
                value={study.studyDetails?.patientPhoneNumber}
                data-cy="patient-phone-number"
              />
            )}

            {/* PATIENT ADDRESS */}
            {!isTriageItem && (
              <StudyDetailsItem
                icon={Place}
                value={study.studyDetails?.patientAddress}
                data-cy="patient-address"
              />
            )}

            {/* PATIENT NOTES */}
            {!isTriageItem && (
              <StudyDetailsItem
                icon={Comment}
                value={
                  <ShowMoreText>
                    <LinkifyText text={study.studyDetails?.patientNotes} />
                  </ShowMoreText>
                }
                data-cy="patient-notes"
              />
            )}
          </List>
        </Grid>
        <Grid size={{xs: 10, sm: 5, md: 2}}>
          <List dense disablePadding sx={{p: 1}}>
            {/* PHYSICIAN NAME */}
            <StudyDetailsItem
              icon={SupervisorAccount}
              value={study.studyDetails?.physicianName}
              data-cy="physician-name"
            >
              {!isTriageItem && (
                <StudyDetailsDialog
                  readOnly
                  study={study}
                  studyId={study.id}
                  studyFacilityId={study.facilityId}
                />
              )}
            </StudyDetailsItem>

            {/* PHYSICIAN PHONE NUMBER */}
            <StudyDetailsItem
              icon={Phone}
              value={study.studyDetails?.physicianPhoneNumber}
              data-cy="physician-phone-number"
            />

            {/* PHYSICIAN EMAIL */}
            <StudyDetailsItem
              icon={Email}
              value={study.studyDetails?.physicianEmail}
              data-cy="physician-email"
            />

            {/* PHYSICIAN FACILITY */}
            <StudyDetailsItem
              icon={Business}
              value={study.studyDetails?.physicianFacility}
              data-cy="physician-facility"
            />

            {/* PHYSICIAN ADDRESS */}
            <StudyDetailsItem
              icon={Place}
              value={study.studyDetails?.physicianAddress}
              data-cy="physician-address"
            />

            {/* PHYSICIAN NOTES (Notification Criteria) */}
            <StudyDetailsItem
              icon={Comment}
              value={
                <ShowMoreText>
                  <LinkifyText text={study.studyDetails?.physicianNotes} />
                </ShowMoreText>
              }
              data-cy="physician-notification-criteria"
            />
          </List>
        </Grid>
      </Grid>
    </Box>
  );
}

StudyDetails.propTypes = {
  study: PropTypes.object.isRequired,
  isTriageItem: PropTypes.bool,
  lastModifiedByUser: PropTypes.string,
};
export default StudyDetails;
