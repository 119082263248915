import React from "react";
import {getFontEmbedCSS, toJpeg} from "html-to-image";
import groupBy from "lodash/groupBy";
import {PageSizes, PDFDict, PDFDocument, PDFName, PDFString} from "pdf-lib";

function determineLinkPosition(sheetElement, linkElement, pageHeight, scaleDPI) {
  const linkSheetBounds = sheetElement.getBoundingClientRect();
  const linkBounds = linkElement.parentElement.getBoundingClientRect();

  // these coordinates are relative to the upper left (typical HTML origin) at 96dpi
  const xOffset = linkBounds.x - linkSheetBounds.x;
  const yOffset = linkBounds.y - linkSheetBounds.y;
  const {width, height} = linkBounds;

  // these coordinates are relative to the lower left (pdf-lib origin) at the PDF's dpi
  const startX = scaleDPI(xOffset);
  const endX = startX + scaleDPI(width);
  const startY = pageHeight - scaleDPI(yOffset + height);
  const endY = startY + scaleDPI(height);

  return [startX, startY, endX, endY];
}

function determineTargetY(sheetElement, targetElement, pageHeight, scaleDPI) {
  const targetSheetBounds = sheetElement.getBoundingClientRect();
  const targetBounds = targetElement.getBoundingClientRect();

  // these coordinates are relative to the upper left (typical HTML origin) at 96dpi
  const yOffset = targetBounds.y - targetSheetBounds.y;

  // these coordinates are relative to the lower left (pdf-lib origin) at the PDF's dpi
  return pageHeight - scaleDPI(yOffset);
}

/**
 * Creates an internal link on a PDF
 * Adapted from https://github.com/Hopding/pdf-lib/issues/123
 *
 * Refer to: https://opensource.adobe.com/dc-acrobat-sdk-docs/pdfstandards/PDF32000_2008.pdf
 * See Section 12.5 for specifications on Annotations
 * See Section 12.5.6.5 for specifications on Link Annotations
 * See Section 12.3.2 for specifications on Destinations
 *
 * @param {Object} pdfDoc
 * @param {Number} targetPage
 * @param {Number} targetY
 * @param {Array<Number>} linkPosition [startX, startY, endX, endY]
 * @returns {PDFObject}
 */
function createLink({pdfDoc, targetPage, targetY, linkPosition}) {
  const pageRef = pdfDoc.getPage(targetPage).ref;

  return pdfDoc.context.register(
    pdfDoc.context.obj({
      Type: "Annot",
      Subtype: "Link",
      // Define a destination in XYZ mode (X-Y-Zoom viewport)
      // Values of null will not affect the viewer when the link is clicked
      Dest: [pageRef, "XYZ", null, targetY, null],
      Rect: linkPosition,
    })
  );
}

/**
 * Finds links within the given sheets and converts them to match pdf-lib format
 * Then adds annotations to the document
 *
 * @param {Object} pdfDoc
 * @param {Array<Element>} sheetArray
 * @param {Number} pageHeight in the PDF's dpi
 * @param {Function} scaleDPI
 */
function addInternalLinks(pdfDoc, sheetArray, pageHeight, scaleDPI) {
  // Find all of the elements on the page that are linked by other elements on the page
  const links = [];
  Array.from(document.links).forEach((linkElement) => {
    const linkPage = sheetArray.findIndex((e) => e.contains(linkElement));
    if (linkPage === -1) {
      // only use link if it occurs somewhere in the report
      return;
    }

    const regexp = /#(?<targetId>[^/]+)$/;
    const matches = linkElement.href?.match(regexp);
    if (!matches) {
      // only use link if it ends in an element id
      return;
    }

    const {targetId} = matches.groups;
    const targetElement = document.getElementById(targetId);
    if (!targetElement) {
      // only use link if it links to a valid element
      return;
    }

    const targetPage = sheetArray.findIndex((e) => e.contains(targetElement));
    if (targetPage === -1) {
      // only use link if the target occurs somewhere in the report
      return;
    }

    links.push({
      pdfDoc,
      linkPage,
      linkPosition: determineLinkPosition(sheetArray[linkPage], linkElement, pageHeight, scaleDPI),
      targetPage,
      targetY: determineTargetY(sheetArray[targetPage], targetElement, pageHeight, scaleDPI),
    });
  });

  // Links must be grouped by page because each page in a PDF can only have one "Annots" entry
  const linksGroupedByPage = groupBy(links, "linkPage");
  Object.entries(linksGroupedByPage).forEach(([linkPage, linksForThisPage]) => {
    // Create clickable regions to navigate each link
    const annotations = pdfDoc.context.obj(linksForThisPage.map((link) => createLink(link)));

    pdfDoc.getPage(Number(linkPage)).node.set(PDFName.of("Annots"), annotations);
  });
}

function createHeaders(pdfDoc, headers, parentRef) {
  if (headers.length === 0) {
    // Exit and return no metadata
    return {};
  }

  // Transform Header Entries for this hierarchical level
  headers.forEach(({displayedText, targetPageRef, targetY, itemRef, headers: subHeaders}, i) => {
    const isLast = i === headers.length - 1;
    const nextOrPrev = isLast ? "Prev" : "Next";
    const nextOrPrevRef = headers[i + (isLast ? -1 : 1)]?.itemRef;

    // Add Entry to Outline
    pdfDoc.context.assign(
      itemRef,
      PDFDict.fromMapWithContext(
        pdfDoc.context.obj({
          Title: PDFString.of(displayedText),
          Parent: parentRef,
          // Every entry points to the next entry, except for the last one, which points to the previous
          [nextOrPrev]: nextOrPrevRef,
          Dest: [targetPageRef, "XYZ", 0, targetY, 0],
          // Recurse over sub headers
          ...createHeaders(pdfDoc, subHeaders, itemRef),
        }),
        pdfDoc.context
      )
    );
  });

  // Return metadata so that its parent can link properly
  return {
    First: headers[0].itemRef,
    Last: headers[headers.length - 1].itemRef,
    Count: headers.length,
  };
}

/**
 * Adds a PDF outline to the document based on the section headers within the given sheets
 * Note: Only supports 1 level of indentation
 *
 * @param {Object} pdfDoc
 * @param {Array<Element>} sheetArray
 * @param {Number} pageHeight in the PDF's dpi
 * @param {Function} scaleDPI
 */
function addPdfOutline(pdfDoc, sheetArray, pageHeight, scaleDPI) {
  // Find elements with attribute "section-header"
  const headers = Array.from(document.querySelectorAll("[section-header]")).reduce((acc, headerElement) => {
    // only use header if it occurs somewhere in the report
    const targetPage = sheetArray.findIndex((e) => e.contains(headerElement));
    if (targetPage !== -1) {
      // Empty tags are "" while omitted tags are null
      const isParent = acc.length === 0 || headerElement.getAttribute("section-parent") === "";
      const arrayToUse = isParent ? acc : acc[acc.length - 1].headers;

      arrayToUse.push({
        displayedText: headerElement.getAttribute("section-header"),
        targetPageRef: pdfDoc.getPage(targetPage).ref,
        targetY: determineTargetY(sheetArray[targetPage], headerElement, pageHeight, scaleDPI),
        itemRef: pdfDoc.context.nextRef(),
        headers: [],
      });
    }
    return acc;
  }, []);

  const outlineRef = pdfDoc.context.nextRef();

  // Register Outline
  pdfDoc.context.assign(
    outlineRef,
    PDFDict.fromMapWithContext(
      pdfDoc.context.obj({
        Type: "Outlines",
        // Create Headers recursively
        ...createHeaders(pdfDoc, headers, outlineRef),
      }),
      pdfDoc.context
    )
  );

  // Add Outline to Document
  pdfDoc.catalog.set(PDFName.of("Outlines"), outlineRef);
}

const useConvertToPDF = (setMessage) => {
  return React.useCallback(
    async (className) => {
      const sheetElements = document.getElementsByClassName(className);
      if (!sheetElements?.length) {
        throw new Error("No sheets found when converting to PDF");
      }

      const pdfDoc = await PDFDocument.create();
      const [PAGE_WIDTH, PAGE_HEIGHT] = PageSizes.Letter;

      const fontEmbedCSS = await getFontEmbedCSS(document.body);

      for (let i = 0; i < sheetElements.length; i++) {
        setMessage(i + 1, sheetElements.length);

        // Convert the elements to a dataURL
        // eslint-disable-next-line no-await-in-loop
        const sheetDataUrl = await toJpeg(sheetElements[i], {
          fontEmbedCSS,
          pixelRatio: 300 / 96,
          quality: 0.5,
        });

        // Add a blank page to the PDF
        const newPage = pdfDoc.addPage([PAGE_WIDTH, PAGE_HEIGHT]);

        // Embed the image in the document and position it on the new page (fill page)
        // eslint-disable-next-line no-await-in-loop
        newPage.drawImage(await pdfDoc.embedJpg(sheetDataUrl), {
          x: 0,
          y: 0,
          width: newPage.getWidth(),
          height: newPage.getHeight(),
        });
      }

      const scaleDPI = (v) => v * (72 / 96);
      addInternalLinks(pdfDoc, Array.from(sheetElements), PAGE_HEIGHT, scaleDPI);
      addPdfOutline(pdfDoc, Array.from(sheetElements), PAGE_HEIGHT, scaleDPI);

      return (await pdfDoc.save()).buffer;
    },
    [setMessage]
  );
};

export default useConvertToPDF;
