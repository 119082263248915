import React from "react";
import {jsPDF as JSPDF} from "jspdf";
import {PDFDocument} from "pdf-lib";

//---------------------------------------------------------------------------
// MUI
//---------------------------------------------------------------------------
import {useTheme} from "@mui/material/styles";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import {createAndFormatLogo} from "../../shared/react/Logo.js";
import {formatDateAndTime} from "../DateAndTime/DateAndTime.jsx";
import useEnvironmentVariables from "./useEnvironmentVariables.jsx";
import useJwt from "./useJwt.jsx";
import useStudyTypeNames from "./useStudyTypeNames.jsx";

const page = {
  height: 11,
  width: 8.5,
  margin: 0.333,
  tab1: 2.2, // Signature Labels
  padding: 0.25,
  dpi: 96,
};
page.rightMargin = page.width - page.margin;
page.headerHeight = 1 - page.margin;
page.tab2 = page.tab1 + page.padding; // Signature Values

const useAddCoverPageToPDF = (report, logo) => {
  const {softwareVersion} = useEnvironmentVariables();
  const displayableStudyTypes = useStudyTypeNames("complex");
  const theme = useTheme();
  const {isInAnyRole, fullName} = useJwt();

  const generateCoverPage = React.useCallback(
    async (pageCount, study, timestamp, comment = "") => {
      const reportTitle = `${
        displayableStudyTypes[report.studyType]?.short || displayableStudyTypes[report.studyType]
      } Report`;
      const patientName =
        report.studyDetails?.patientName === report.tzSerial ? "" : report.studyDetails?.patientName;
      const studyStartDate = formatDateAndTime({
        datetime: study.studyStartDate,
        zone: study.timeZone,
      });
      const recordingDuration = Math.ceil(study.recordedDuration / 24);
      const displayedRecordingDuration = `${recordingDuration} day${recordingDuration === 1 ? "" : "s"}`;
      const displayedStudyType =
        displayableStudyTypes[report.studyType]?.long || displayableStudyTypes[report.studyType];

      const displayedSignature = timestamp ? `Electronically signed by ${fullName}` : "";
      const displayedTimestamp = timestamp
        ? formatDateAndTime({datetime: timestamp, zone: study.timeZone})
        : "";
      const displayedName = isInAnyRole(["physician"]) ? fullName : "";

      const doc = new JSPDF({
        unit: "in",
        format: "letter",
        orientation: "portrait",
      });

      /**
       * Returns line height
       * @param {Number} fontPoint
       * @param {Number} [heightFactor] Line Spacing; jsPDF uses 1.15 by default
       * @returns {Number} line height in inches
       */
      const calculateLineHeight = (fontPoint, heightFactor = doc.getLineHeightFactor()) => {
        const pxPerPt = 4 / 3;
        return (fontPoint * pxPerPt * heightFactor) / page.dpi;
      };

      //---------------------------------------------------------------------------
      // Header
      //---------------------------------------------------------------------------
      if (logo) {
        const {src, format, x, y, w, h} = await createAndFormatLogo(page, logo.src, logo.extension);
        doc.addImage(src, format, x, y, w, h);
      }

      const halfPageWidth = page.width / 2;
      const rightBound = page.rightMargin - page.padding;
      const leftBound = page.margin + page.padding;
      const lowerBound = page.height - page.margin;
      const footerY = lowerBound - page.padding;
      const headerY = page.headerHeight + page.margin;
      doc
        // Title (bold weight)
        .setFont("Helvetica", "bold")
        .setFontSize(14)
        .setTextColor(theme.palette.primary.dark)
        .text(reportTitle, halfPageWidth, 0.65, {align: "center"})
        // Subtitle (bold weight)
        .setFontSize(9)
        .setTextColor(theme.palette.tertiary.light)
        .text(`Study ID: ${report.studyId}`, halfPageWidth, 0.9, {align: "center"})
        // Header Info (bold weight)
        .text(`Patient: ${patientName}`, page.margin, 0.6)
        .text(`Patient ID: ${report.studyDetails?.patientId}`, page.margin, 0.75)
        .text(`Device: ${report.tzSerial}`, page.margin, 0.9);

      //---------------------------------------------------------------------------
      // Body
      //---------------------------------------------------------------------------
      // Elements of infoTable.columns are columns (a third column could be added and it will resize)
      //   Elements in each of those elements are rows
      //     Elements in each of those elements are strings.
      //     A solo string is treated as a title, and two strings will be displayed as a key/value pair
      const infoTable = {
        topY: headerY + 0.3, // Starts below the header
        leftX: page.margin,
        rightX: page.rightMargin,
        keyRatio: 0.2, // 20% of each column will be the key
        columns: [
          [
            ["Patient Summary:"],
            ["Name:", report.studyDetails?.patientName],
            ["Patient ID:", report.studyDetails?.patientId],
            ["Language:", report.studyDetails?.patientLanguage],
            ["DOB:", report.studyDetails?.patientDob?.replaceAll("/", "-") || ""], // @TODO BN-3380: remove replaceAll when date is stored in ISO
            ["Gender:", report.studyDetails?.patientGender],
            ["Height:", report.studyDetails?.patientHeight],
            ["Weight:", report.studyDetails?.patientWeight],
            ["Phone:", report.studyDetails?.patientPhoneNumber],
            ["Address:", report.studyDetails?.patientAddress],
          ],
          [
            ["Study Summary:"],
            ["Device:", report.tzSerial],
            ["Study ID:", report.studyId],
            ["Start Date:", studyStartDate],
            ["Duration:", displayedRecordingDuration],
            ["Study Type:", displayedStudyType],
            ["Facility:", report.facilityName],
            ["Indication:", study.studyIndication],
          ],
        ],
      };

      doc.setTextColor(theme.palette.grey[900]).setFontSize(9);
      let maxColumnHeight = 0;

      // Procedurally add infoTable to doc (nested loop)
      infoTable.columns.forEach((column, c) => {
        const titleRowHeight = 0.25;
        const rowHeight = 0.2;
        const columnWidth = (infoTable.rightX - infoTable.leftX) / infoTable.columns.length;
        const leftX = infoTable.leftX + columnWidth * c;
        const rightX = leftX + columnWidth;

        const keyTab = leftX + columnWidth * infoTable.keyRatio;
        const valueTab = keyTab + 0.1;
        const valueWidth = rightX - valueTab - 0.1;

        let rowHeightCounter = infoTable.topY;
        column.forEach((row) => {
          if (row.length === 0) {
            // Display row as blank
            rowHeightCounter += rowHeight;
          } else if (row.length === 1) {
            // Display row as a title (centered, bold)
            doc
              .setFont("Helvetica", "bold")
              .text(`${row[0]}`, (leftX + rightX) / 2, rowHeightCounter, {align: "center"});
            rowHeightCounter += titleRowHeight;
          } else {
            // Display row as key (bold) and value
            doc
              .setFont("Helvetica", "bold")
              .text(`${row[0]}`, keyTab, rowHeightCounter, {align: "right"})
              .setFont("Helvetica", "")
              .text(`${row[1]}`, valueTab, rowHeightCounter, {maxWidth: valueWidth});

            // Use jsPDF plugin to calculate how many lines were used
            const additionalRows = doc.splitTextToSize(`${row[1]}`, valueWidth).length - 1;
            rowHeightCounter += rowHeight + additionalRows * calculateLineHeight(9);
          }
        });

        maxColumnHeight = Math.max(maxColumnHeight, rowHeightCounter - rowHeight);
      });

      // Start the next elements on the page at a height based on maxColumnHeight
      const physicianFieldY = maxColumnHeight + 0.1;

      // Draw the Left Vertical Grey Line by the info table
      doc
        .setDrawColor(theme.palette.background.main)
        .setLineWidth(3 / page.dpi)
        .line(infoTable.leftX, headerY, infoTable.leftX, physicianFieldY);

      // Draw vertical grey lines on the right of each column of the info table
      // This loop needs to be duplicated because the height of all of these lines is calculated by maxColumnHeight
      infoTable.columns.forEach((column, c) => {
        const columnWidth = (infoTable.rightX - infoTable.leftX) / infoTable.columns.length;
        const rightX = infoTable.leftX + columnWidth * (c + 1);

        doc.line(rightX, headerY, rightX, physicianFieldY);
      });

      // Physician Signature and Comments
      doc
        .setTextColor(theme.palette.grey[900])
        .text("Reading Physician Name:", page.tab1, physicianFieldY + 0.4, {align: "right"})
        .text("Reading Physician Signature:", page.tab1, physicianFieldY + 0.7, {align: "right"})
        .text("Date:", page.tab1, physicianFieldY + 1, {align: "right"})
        .text("Reading Physician Comments:", leftBound, physicianFieldY + 1.7)
        .setFont("Helvetica", "")
        .text(displayedName, page.tab2, physicianFieldY + 0.4)
        .text(displayedSignature, page.tab2, physicianFieldY + 0.7)
        .text(displayedTimestamp, page.tab2, physicianFieldY + 1)
        // Dynamic Physician Fields
        .text(comment, leftBound, physicianFieldY + 2, {maxWidth: page.width - 2 * leftBound});

      // Lines
      doc
        // Grey Lines around the physician fields
        .setDrawColor(theme.palette.background.main)
        .setLineWidth(3 / page.dpi)
        .line(page.margin, physicianFieldY, page.rightMargin, physicianFieldY)
        .line(page.margin, physicianFieldY + 1.35, page.rightMargin, physicianFieldY + 1.35)
        .line(page.margin, physicianFieldY, page.margin, footerY)
        .line(page.rightMargin, physicianFieldY, page.rightMargin, footerY)
        // Signature Lines
        .setDrawColor(theme.palette.grey[900])
        .setLineWidth(1 / page.dpi)
        .setLineCap("square")
        .line(page.tab2 - 0.1, physicianFieldY + 0.45, rightBound, physicianFieldY + 0.45)
        .line(page.tab2 - 0.1, physicianFieldY + 0.75, rightBound, physicianFieldY + 0.75)
        .line(page.tab2 - 0.1, physicianFieldY + 1.05, rightBound, physicianFieldY + 1.05)
        // Header & Footer Separators
        .setDrawColor(theme.palette.primary.main)
        .setLineWidth(3 / page.dpi)
        .line(page.margin, headerY, page.rightMargin, headerY)
        .line(page.margin, footerY, page.rightMargin, footerY);

      //---------------------------------------------------------------------------
      // Footer
      //---------------------------------------------------------------------------
      const reportNumberInfo = `Report Number: ${report.reportNumber}`;
      const renderedInfo = `Rendered in: ${softwareVersion}`;
      const pageInfo = `Cover Page (${pageCount} page report)`;
      doc
        .setTextColor(theme.palette.tertiary.light)
        .text(reportNumberInfo, page.margin, lowerBound - 0.1)
        .text(renderedInfo, halfPageWidth, lowerBound - 0.1, {align: "center"})
        .text(pageInfo, page.rightMargin, lowerBound - 0.1, {align: "right"});

      // Finalize
      return doc.output("arraybuffer");
    },
    [displayableStudyTypes, fullName, isInAnyRole, logo, report, softwareVersion, theme]
  );

  return React.useCallback(
    async (fileURL, study, timestamp, comment) => {
      //---------------------------------------------------------------------------
      // Combine the cover page with the report using pdf-lib
      //---------------------------------------------------------------------------
      const fullDoc = await PDFDocument.create();

      // Get the report from the browser's file system
      const reportBuffer = await (await fetch(fileURL)).arrayBuffer();
      const reportDoc = await PDFDocument.load(reportBuffer);
      const reportPages = await fullDoc.copyPages(reportDoc, reportDoc.getPageIndices());

      // Create an in-memory cover page using jsPDF
      const coverBuffer = await generateCoverPage(reportPages.length, study, timestamp, comment);
      const coverDoc = await PDFDocument.load(coverBuffer);
      const coverPage = await fullDoc.copyPages(coverDoc, coverDoc.getPageIndices());

      // Add Pages
      coverPage.forEach((p) => fullDoc.addPage(p));
      reportPages.forEach((p) => fullDoc.addPage(p));

      // Finalize Doc
      const outputBytes = await fullDoc.save();
      return new File([outputBytes], "file.pdf", {type: "application/pdf"});
    },
    [generateCoverPage]
  );
};

export default useAddCoverPageToPDF;
