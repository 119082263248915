import React from "react";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// TZ Components
//---------------------------------------------------------------------------
import {useFilter, useInterval, useSort} from "@tzmedical/react-hooks";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import axios from "../../axiosClient.js";
import {InboxEntityProvider} from "../../contexts/InboxEntityContext.jsx";
import {
  useStudies,
  useStudiesDispatch,
  useStudiesStatusFilter,
  useStudiesTypeFilter,
} from "../../contexts/StudiesContext.jsx";
import NewStudyDialog from "../../dialogs/StudyDialogs/NewStudy/NewStudyDialog.jsx";
import Alert from "../../shared/react/Alert.jsx";
import FloatingActionBuffer from "../../shared/react/FloatingActionBuffer.jsx";
import NoResults from "../../shared/react/NoResults.jsx";
import Pagination from "../../shared/react/Pagination.jsx";
import TableLoading from "../../shared/react/TableLoading.jsx";
import {doesNotMeetExtendedHolterRequirements} from "../hooks/useDoesNotMeetExtendedHolterRequirements.jsx";
import useEnvironmentVariables from "../hooks/useEnvironmentVariables.jsx";
import StudiesHeader from "./StudiesHeader.jsx";
import StudiesRow from "./StudiesRow.jsx";

// Unless we can get socket.io or long polling working, fetching the data
// every 15 seconds should keep things from getting "stale"
const DATA_REFRESH_INTERVAL_MS = 15000;

//---------------------------------------------------------------------------
// Search and Sort options
//---------------------------------------------------------------------------
const constantSearchFields = {
  patient: [(object) => object.studyDetails?.patientName, (object) => object.studyDetails?.patientId],
  device: (object) => object.currentEnrollment?.tzSerial,
  id: "id",
  study: "id",
  order: "orderNumber",
  indication: "studyIndication",
  is: {
    unconfigured: (object) => !!object.currentEnrollment?.pendingSettingsDownload,
  },
  start: "studyStartDate",
  end: "studyEndDate",
  finalized: "finalizedAt",
  facility: ["facilityId", (object) => object.facility?.name],
  recording: (object) => object.currentEnrollment?.deviceEnrollmentId,
  dob: (object) => object.studyDetails?.patientDob?.replaceAll("/", "-"), // @TODO BN-3380: remove replaceAll when date is stored in ISO
  language: (object) => object.studyDetails?.patientLanguage,
  gender: (object) => object.studyDetails?.patientGender,
  height: (object) => object.studyDetails?.patientHeight,
  weight: (object) => object.studyDetails?.patientWeight,
  phone: (object) => object.studyDetails?.patientPhone,
  emergencyContact: (object) => object.studyDetails?.emergencyContactName,
  emergencyPhone: (object) => object.studyDetails?.emergencyContactPhone,
  address: (object) => object.studyDetails?.patientAddress,
  "physician-name": (object) => object.studyDetails?.physicianName,
  "physician-phone": (object) => object.studyDetails?.physicianPhone,
  "physician-facility": (object) => object.studyDetails?.physicianFacility,
  "physician-email": (object) => object.studyDetails?.physicianEmail,
  "physician-address": (object) => object.studyDetails?.physicianAddress,
  physician: [
    (object) => object.studyDetails?.physicianName,
    (object) => object.studyDetails?.physicianPhone,
    (object) => object.studyDetails?.physicianFacility,
    (object) => object.studyDetails?.physicianEmail,
    (object) => object.studyDetails?.physicianAddress,
  ],
  // This is a little messy, but so is the underlying intent - to be able to search any arbitrary number of array elements on any property.
  insurance: [
    (object) => JSON.stringify(object.insurance?.map((insurancePlan) => insurancePlan.type?.name)),
    (object) => JSON.stringify(object.insurance?.map((insurancePlan) => insurancePlan.insuranceCompany)),
    (object) => JSON.stringify(object.insurance?.map((insurancePlan) => insurancePlan.relationToPatient)),
    (object) => JSON.stringify(object.insurance?.map((insurancePlan) => insurancePlan.subscriberIdNumber)),
    (object) => JSON.stringify(object.insurance?.map((insurancePlan) => insurancePlan.subscriberName)),
    (object) => JSON.stringify(object.insurance?.map((insurancePlan) => insurancePlan.subscriberDob)),
    (object) => JSON.stringify(object.insurance?.map((insurancePlan) => insurancePlan.subscriberSex)),
    (object) => JSON.stringify(object.insurance?.map((insurancePlan) => insurancePlan.subscriberAddress)),
    (object) => JSON.stringify(object.insurance?.map((insurancePlan) => insurancePlan.policyNumber)),
    (object) => JSON.stringify(object.insurance?.map((insurancePlan) => insurancePlan.groupNumber)),
    (object) => JSON.stringify(object.insurance?.map((insurancePlan) => insurancePlan.claimsPhoneNumber)),
    (object) => JSON.stringify(object.insurance?.map((insurancePlan) => insurancePlan.claimsAddress)),
    (object) =>
      JSON.stringify(object.insurance?.map((insurancePlan) => insurancePlan.priorAuthorizationNumber)),
  ],
  contract: {
    none: (object) => object.inboxContract === "none",
    device: (object) => object.inboxContract === "feePerDevice",
    service: (object) => object.inboxContract === "feePerService",
    split: (object) => object.inboxContract === "splitBilling",
    evaluation: (object) => object.inboxContract === "evaluation",
  },
};
const sortOptions = {
  defaultSort: {
    field: "studyStartDate",
    reverse: true,
  },
  fieldGetters: {
    patientName: (study) => study.studyDetails?.patientName,
    status: (study) => {
      if (study.studyState === "finalized") {
        return "Finalized";
      }
      if (study.studyState === "archived") {
        return "Archived";
      }
      if (study.studyState === "failed") {
        return "Failed";
      }
      if (!study.dataReceived && study.studyState === "active") {
        return "Pending";
      }
      if (!study.studyEndDate && study.studyState === "active") {
        return "Recording";
      }
      if (study.studyEndDate && study.studyState === "active") {
        return "Done Recording";
      }
      return "Failed";
    },
  },
};

function StudiesTable({
  // Props
  searchText = "",
}) {
  //---------------------------------------------------------------------------
  // Global Variables
  //---------------------------------------------------------------------------
  const {features} = useEnvironmentVariables();

  //---------------------------------------------------------------------------
  // Error alerting state management
  //---------------------------------------------------------------------------
  const [error, setError] = React.useState(null);

  //---------------------------------------------------------------------------
  // Load data from the API
  //---------------------------------------------------------------------------
  const [tableLoading, setTableLoading] = React.useState(true);

  const studies = useStudies();
  const dispatch = useStudiesDispatch();

  const axiosAbortController = React.useRef();

  // Status filter
  const {statusFilter} = useStudiesStatusFilter();

  // Study types filter
  const {typeFilter} = useStudiesTypeFilter();

  const getStudies = React.useCallback(async () => {
    try {
      // filter out the toggles that are turned off
      const typeParams = Object.keys(typeFilter).filter((type) => typeFilter[type]);
      // WARNING: axios strips off empty strings
      // if there are no filters toggled on let the back end know
      if (typeParams.length === 0) {
        typeParams.push("none");
      }

      // filter out the toggles that are turned off
      const statusParams = Object.keys(statusFilter).filter((status) => statusFilter[status]);
      // WARNING: axios strips off empty strings
      // if there are no filters toggled on let the back end know
      if (statusParams.length === 0) {
        statusParams.push("none");
      }

      // abort the previous request to avoid race conditions
      if (axiosAbortController.current) {
        axiosAbortController.current.abort();
      }
      axiosAbortController.current = new AbortController();

      const [{data: studiesResponse}, {data: actionsResponse}] = await Promise.all([
        axios({
          method: "get",
          url: "/studies",
          params: {
            type: typeParams,
            status: statusParams,
          },
          signal: axiosAbortController.current.signal,
        }),
        // For each format device action, set checkInDeviceInProgress to true on the study
        axios({
          method: "get",
          url: "/actions",
          params: {
            name: "formatDevice",
            status: {$or: ["pending", "sent"]},
            attributes: ["enrollmentId", "name", "deviceId"],
          },
        }),
      ]);

      actionsResponse.forEach((action) => {
        const correspondingStudy = studiesResponse.find((study) => {
          return (
            study.enrollmentId === action.enrollmentId &&
            study.currentEnrollment?.deviceId === action.deviceId
          );
        });
        if (correspondingStudy) {
          correspondingStudy.checkInDeviceInProgress = true;
        }
      });

      dispatch({type: "init", elements: studiesResponse});
      setTableLoading(false);
      setError(null);
    } catch (err) {
      if (err.message === "canceled") {
        // If the axios request was cancelled by a new axios request, don't display an error, and don't set loading to false
        setError(null);
      } else if (err.response?.status === 500 && studies.length > 0) {
        console.error(err);

        // Don't display error in this case
        setError(null);
        setTableLoading(false);
      } else {
        console.error(err);

        // Display all other errors
        setError(err?.message);
        setTableLoading(false);
      }
    }
  }, [dispatch, statusFilter, typeFilter, studies.length]);

  useInterval(getStudies, DATA_REFRESH_INTERVAL_MS, tableLoading);

  //---------------------------------------------------------------------------
  // Search support
  //---------------------------------------------------------------------------
  const searchFields = React.useMemo(() => {
    return {
      ...constantSearchFields,
      ...(features.downgradeAuthorized && {
        is: {
          ...constantSearchFields.is,
          downgradeAuthorized: (object) => object.downgradeAuthorized,
          meetingReportCriteria: (object) => {
            return !doesNotMeetExtendedHolterRequirements(
              object?.studyType,
              object?.downgradeAuthorized,
              object?.configuredDuration,
              object?.recordedDuration
            );
          },
        },
      }),
      "study-type": {
        mct: (object) => object.studyType === "mct",
        mctWithFullDisclosure: (object) => object.studyType === "mctWithFullDisclosure",
        cem: (object) => object.studyType === "cem",
        holter: (object) => object.studyType === "holter",
        extendedHolter: (object) => object.studyType === "extendedHolter",
        wirelessHolter: (object) => object.studyType === "wirelessHolter",
        wirelessExtendedHolter: (object) => object.studyType === "wirelessExtendedHolter",
        cardiacRehab: (object) => object.studyType === "cardiacRehab",
      },
    };
  }, [features.downgradeAuthorized]);

  const filteredStudies = useFilter(studies, searchText, searchFields);

  //---------------------------------------------------------------------------
  // Sorting support
  //---------------------------------------------------------------------------
  const [sortedStudies, handleSortSelection, sort] = useSort(filteredStudies, sortOptions);

  //---------------------------------------------------------------------------
  // Pagination support
  //---------------------------------------------------------------------------
  const [page, setPage] = React.useState(0);
  const pageSize = 50;
  const pageStudies = React.useMemo(
    () => sortedStudies.slice(page * pageSize, (page + 1) * pageSize),
    [page, sortedStudies]
  );
  React.useEffect(() => setPage(0), [searchText]);

  return (
    <>
      <Alert message={error} setMessage={setError} level="error" variant="snackbar" />

      {
        //---------------------------------------------------------------------------
        // Display a loading spinner if we're still waiting on the API
        //---------------------------------------------------------------------------
        tableLoading && <TableLoading />
      }
      {!tableLoading && (
        <>
          <StudiesHeader
            sort={sort}
            setSort={handleSortSelection}
            sortedStudies={sortedStudies}
            setFilterLoading={setTableLoading}
          />

          {
            //---------------------------------------------------------------------------
            // Display a message if there are no matching results, instead of the table
            //---------------------------------------------------------------------------
            sortedStudies.length === 0 && <NoResults />
          }

          {
            //---------------------------------------------------------------------------
            // Render the table and pagination
            //---------------------------------------------------------------------------
            sortedStudies.length > 0 && (
              <>
                {pageStudies.map((study) => (
                  <InboxEntityProvider key={study.id} type="study">
                    <StudiesRow study={study} alwaysOpen={pageStudies.length === 1} />
                  </InboxEntityProvider>
                ))}
                <Pagination pageSize={pageSize} page={page} setPage={setPage} count={sortedStudies.length} />
              </>
            )
          }

          <NewStudyDialog />
        </>
      )}
      <FloatingActionBuffer />
    </>
  );
}
StudiesTable.propTypes = {
  searchText: PropTypes.string,
};
export default StudiesTable;
