import React from "react";

/**
 * Supports up to 3 digits in each place value
 *
 * @param {String} versionString E.g. "v1.3.2"
 * @returns {String} E.g. 1-001-003-002-0"
 * @returns {null} if invalid
 */
function getPaddedVersion(versionString) {
  const regex = /^v?(?<major>\d{1,3})\.(?<minor>\d{1,3})\.(?<patch>\d{1,3})(-(?<dirt>.+))?$/;
  let matches = regex.exec(versionString);

  if (matches === null) {
    const betaRegex =
      /^v?(?<betaChar>0)\.(?<major>\d{1,3})\.(?<minor>\d{1,3})\.(?<patch>\d{1,3})(-(?<dirt>.+))?$/;
    matches = betaRegex.exec(versionString);
  }

  if (matches === null) {
    // Then this is not a valid version, so exit.
    throw new Error(`Invalid Version String "${versionString}"`);
  }

  const fullReleaseFlag = Number(!matches.groups.betaChar);
  const {major, minor, patch} = matches.groups;
  const dirtyFlag = Number(!!matches.groups.dirt);
  return `${fullReleaseFlag}-${major.padStart(3, "0")}-${minor.padStart(3, "0")}-${patch.padStart(3, "0")}-${dirtyFlag}`;
}

/**
 * @param {String} versionA The Version of the entity, such as a report version
 * @param {String} op "===", ">", "<", ">=", "<="
 * @param {String} versionB The Version to compare against, such as a constant "v1.3.1"
 * @param {Boolean} [returnValueOnError]
 * @returns {Boolean}
 */
const useVersionCompare = () => {
  return React.useCallback((versionA, op, versionB, returnValueOnError = false) => {
    try {
      const paddedFormA = getPaddedVersion(versionA);
      const paddedFormB = getPaddedVersion(versionB);

      switch (op) {
        case "===":
          return paddedFormA === paddedFormB;
        case ">":
          return paddedFormA > paddedFormB;
        case "<":
          return paddedFormA < paddedFormB;
        case ">=":
          return paddedFormA >= paddedFormB;
        case "<=":
          return paddedFormA <= paddedFormB;
        default:
          throw new Error(`Invalid Comparison "${op}"`);
      }
    } catch (err) {
      if ([true, false].includes(returnValueOnError)) {
        return returnValueOnError;
      }
      throw err;
    }
  }, []);
};

export default useVersionCompare;
